.profile {
  margin-top: 80px;
}

@media (max-width: 768px) {
  .profile {
    margin-top: 20px;
  }
}

.search-res {
  padding-top: 80px;
}

@media (max-width: 768px) {
  .search-res {
    padding-top: 10px;
  }
}

.watchlist-res {
  padding-top: 80px;
  margin-left: 50px;
}

@media (max-width: 768px) {
  .watchlist-res {
    padding-top: 10px;
    margin-left: 40px;
  }
}

.help-bold-title {
  font-size: 28px;
  font-weight: 500;
}

.help-semi-bold {
  font-size: 18px;
  font-weight: 600;
  margin-top: 18px;
}

.help-normal-title {
  font-size: 18px;
  margin-top: 12px;
}

.innercontainer {
  background-color: white;
  border-radius: 3px;
  padding: 18px;
}

.title {
  color: #11161f;
}

.circle {
  border-radius: 1000px !important;
  overflow: hidden;
  width: 135px;
  height: 135px;
  border: 3px solid #21baef;
  top: 72px;
}

.proimg {
  max-width: 100%;
  height: auto;
}
.p-image {
  color: #666666;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.p-image:hover {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.profilecircle {
  margin-left: 20px;
  margin-top: 18px;
}

.imageBtn {
  width: 200px;
  margin-top: 30px;
}

.hrcolor {
  color: #11161f;
}

.accentcol {
  color: #21baef;
  font-weight: bold;
  cursor: pointer;
}
