/* Container holding the image and the text */
.about-container {
  position: relative;
  text-align: center;
  color: #fff;
}

.about-text {
  font-size: 40px;
  font-weight: 800;
  text-align: left;
}

.myabout {
  position: absolute;
  top: 40%;
  bottom: 60%;
  width: 50%;
  left: 20px;
}

@media (max-width: 768px) {
  .about-text {
    font-size: 20px;
    font-weight: 300;
  }
  .myabout {
    top: 20%;
    left: 20px;
    width: 50%;
  }
}

.about-small {
  margin-top: 20px;
  font-size: 30px;
}

.about-logo {
  margin: 20px;
}
.about-perspective {
  margin: 30px;
}
