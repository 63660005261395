@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
.movie-genre-page .g-box {
  margin-bottom: 0;
}
.movie-genre-page .grid-playlist-wrap .pl-heading {
  font-size: 24px;
  text-transform: capitalize;
}
.grid-playlist-wrap .grid-playlist {
  display: grid;
  grid-gap: 10px;
  grid-row-gap: 20px;
  grid-column-gap: 10px;
}
@media (max-width: 320px) {
  .grid-playlist-wrap .grid-playlist {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  }
}
@media (min-width: 321px) {
  .grid-playlist-wrap .grid-playlist {
    grid-template-columns: repeat(auto-fill, minmax(145px, 1fr));
  }
}
@media (min-width: 768px) {
  .grid-playlist-wrap .grid-playlist {
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  }
}
@media (min-width: 1024px) {
  .grid-playlist-wrap .grid-playlist {
    grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
  }
}
.grid-playlist-wrap .grid-playlist .l-img {
  object-fit: cover;
  vertical-align: middle;
}
@media (min-width: 1024px) {
  .grid-playlist-wrap.mbox-grid-playlist .grid-playlist {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
.grid-playlist-wrap .pl-heading {
  font-size: 20px;
  font-weight: 500;
  /* margin: 24px 0; */
  text-transform: uppercase;
}
.grid-playlist-wrap .g-box-content-wrap,
.grid-playlist-wrap .h-box .h-box-content-wrap,
.grid-playlist-wrap .m-box,
.grid-playlist-wrap .ma-box-content-wrap {
  margin-right: 0;
  margin-bottom: 10px;
}
.grid-playlist-wrap .e-box .e-box-content-wrap,
.grid-playlist-wrap .e-box .e-box-img,
.grid-playlist-wrap .e-box .g-box-content-wrap,
.grid-playlist-wrap .e-box .g-box-img,
.grid-playlist-wrap .e-box .h-box-content-wrap,
.grid-playlist-wrap .e-box .h-box-img,
.grid-playlist-wrap .e-box .ma-box-content-wrap,
.grid-playlist-wrap .e-box .ma-box-img,
.grid-playlist-wrap .g-box .e-box-content-wrap,
.grid-playlist-wrap .g-box .e-box-img,
.grid-playlist-wrap .g-box .g-box-content-wrap,
.grid-playlist-wrap .g-box .g-box-img,
.grid-playlist-wrap .g-box .h-box-content-wrap,
.grid-playlist-wrap .g-box .h-box-img,
.grid-playlist-wrap .g-box .ma-box-content-wrap,
.grid-playlist-wrap .g-box .ma-box-img,
.grid-playlist-wrap .h-box .e-box-content-wrap,
.grid-playlist-wrap .h-box .e-box-img,
.grid-playlist-wrap .h-box .g-box-content-wrap,
.grid-playlist-wrap .h-box .g-box-img,
.grid-playlist-wrap .h-box .h-box-content-wrap,
.grid-playlist-wrap .h-box .h-box-img,
.grid-playlist-wrap .h-box .ma-box-content-wrap,
.grid-playlist-wrap .h-box .ma-box-img,
.grid-playlist-wrap .ma-box .e-box-content-wrap,
.grid-playlist-wrap .ma-box .e-box-img,
.grid-playlist-wrap .ma-box .g-box-content-wrap,
.grid-playlist-wrap .ma-box .g-box-img,
.grid-playlist-wrap .ma-box .h-box-content-wrap,
.grid-playlist-wrap .ma-box .h-box-img,
.grid-playlist-wrap .ma-box .ma-box-content-wrap,
.grid-playlist-wrap .ma-box .ma-box-img {
  width: 100%;
  height: auto;
  min-height: 80px;
}
.grid-playlist-wrap .e-box .l-img,
.grid-playlist-wrap .e-box .l-img-wrap,
.grid-playlist-wrap .g-box .l-img,
.grid-playlist-wrap .g-box .l-img-wrap,
.grid-playlist-wrap .h-box .l-img,
.grid-playlist-wrap .h-box .l-img-wrap,
.grid-playlist-wrap .ma-box .l-img,
.grid-playlist-wrap .ma-box .l-img-wrap {
  object-fit: cover;
  vertical-align: middle;
}
.grid-playlist-wrap .m-box .m-box-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column;
  flex-flow: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
.grid-playlist-wrap .m-box .m-box-heading-wrap {
  width: 100%;
}
.grid-playlist-wrap .m-box .l-img,
.grid-playlist-wrap .m-box .l-img-wrap {
  width: 100%;
  height: 100%;
  min-height: 80px;
  vertical-align: middle;
}
.grid-playlist-wrap .ma-box .ma-box-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column;
  flex-flow: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
.grid-playlist-wrap .ma-box .ma-box-heading-wrap {
  width: 100%;
  height: unset;
  padding: 5px 0;
}
.grid-playlist-wrap .ma-box .l-img,
.grid-playlist-wrap .ma-box .l-img-wrap {
  width: 100%;
  height: auto;
  min-height: 80px;
  vertical-align: middle;
}
.grid-playlist-wrap .ma-box .ma-box-sub-heading {
  margin: 0;
}
.grid-playlist-wrap .ma-box.rounded .lazy-img-wrap {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
@media (max-width: 320px) {
  .grid-playlist-wrap .ma-box.rounded .l-img,
  .grid-playlist-wrap .ma-box.rounded .l-img-wrap {
    width: 140px;
    height: 140px;
    min-height: 140px;
  }
}
@media (min-width: 321px) {
  .grid-playlist-wrap .ma-box.rounded .l-img,
  .grid-playlist-wrap .ma-box.rounded .l-img-wrap {
    width: 145px;
    height: 145px;
    min-height: 145px;
  }
}
@media (min-width: 768px) {
  .grid-playlist-wrap .ma-box.rounded .l-img,
  .grid-playlist-wrap .ma-box.rounded .l-img-wrap {
    width: 170px;
    height: 170px;
    min-height: 170px;
  }
}
@media (min-width: 1024px) {
  .grid-playlist-wrap .ma-box.rounded .l-img,
  .grid-playlist-wrap .ma-box.rounded .l-img-wrap {
    width: 185px;
    height: 185px;
    min-height: 185px;
  }
}
.grid-playlist-wrap .ma-box.rounded-sq .l-img,
.grid-playlist-wrap .ma-box.rounded-sq .l-img-wrap {
  min-height: 145px;
  vertical-align: middle;
}
.g-box {
  display: -ms-inline-flexbox;
  display: inline-flex;
  transition: transform 0.25s ease-in-out;
  transform: translateZ(0);
  /* 
         */
}
.g-box .g-box-content-wrap {
  display: inline-block;
  text-align: center;
  color: #999;
  /* margin-right: 22px; */
  margin-bottom: 0;
}
.g-box .thumb-wrap {
  position: relative;
}
.g-box.audio-g-box .g-box-img {
  width: 216px;
  height: 216px;
}
.g-box.movie-g-box .g-box-content-wrap,
.g-box.movie-g-box .g-box-img {
  width: 216px;
  height: 122px;
}
.g-box:hover {
  transform: scale(1.08);
}
@media (max-width: 768px) {
  .g-box .g-box-img {
    height: 90px;
    width: 160px;
  }
  .g-box .g-box-content-wrap {
    height: 122px;
    width: 160px;
  }
  .genreimage {
    width: 150px;
    height: 100px;
  }
}
.l-img-wrap {
  width: 100%;
  height: 100%;
}
.l-img-wrap .l-img,
.l-img-wrap .l-img-bg {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.l-img-wrap .l-img-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}
.l-img-wrap .img-placeholder {
  width: 100%;
  height: 100%;
}
.l-img-wrap .l-img-one-fourth {
  width: 50%;
  height: 50%;
  min-width: 5px;
  min-height: 5px;
  background-size: cover;
  vertical-align: middle;
}
