@charset "UTF-8";
/* #### Generated By: http://www.cufonfonts.com #### */
@font-face {
  font-family: "Proxima Nova Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Proxima Nova Regular"),
    url("../assets/font/ProximaNova-Regular.woff") format("woff");
}

@font-face {
  font-family: "Proxima Nova Thin";
  font-style: normal;
  font-weight: normal;
  src: local("Proxima Nova Thin"),
    url("../assets/font/ProximaNovaT-Thin.woff") format("woff");
}

@font-face {
  font-family: "Proxima Nova Light";
  font-style: normal;
  font-weight: normal;
  src: local("Proxima Nova Light"),
    url("../assets/font/ProximaNova-Light.woff") format("woff");
}

@font-face {
  font-family: "Proxima Nova Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("Proxima Nova Semibold"),
    url("../assets/font/ProximaNova-Semibold.woff") format("woff");
}

@font-face {
  font-family: "Proxima Nova Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Proxima Nova Bold"),
    url("../assets/font/ProximaNova-Bold.woff") format("woff");
}

@font-face {
  font-family: "Proxima Nova Extrabold";
  font-style: normal;
  font-weight: normal;
  src: local("Proxima Nova Extrabold"),
    url("../assets/font/ProximaNova-Extrabld.woff") format("woff");
}

@font-face {
  font-family: "Proxima Nova Black";
  font-style: normal;
  font-weight: normal;
  src: local("Proxima Nova Black"),
    url("../assets/font/ProximaNova-Black.woff") format("woff");
}

.loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #11161f;
  z-index: 1031;
  left: 0;
  top: 0;
}

.loader span {
  margin: auto;
  position: relative;
  height: 80px;
  width: 80px;
}

.loader span div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loader span div:nth-child(1) {
  left: 8px;
  background-color: #1782a7;
  -webkit-animation: loader1 0.6s infinite;
  animation: loader1 0.6s infinite;
}

.loader span div:nth-child(2) {
  background-color: #21baef;
  left: 8px;
  -webkit-animation: loader2 0.6s infinite;
  animation: loader2 0.6s infinite;
}

.loader span div:nth-child(3) {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    /* from(#872bd0), */ from(#2145ef) /* color-stop(101.45%, #21baef) */
      color-stop(101.45%, #21baef)
  );
  background: linear-gradient(90deg, #2145ef 0%, #21baef 101.45%);
  left: 32px;
  -webkit-animation: loader2 0.6s infinite;
  animation: loader2 0.6s infinite;
}

.loader span div:nth-child(4) {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2145ef),
    color-stop(101.45%, #21baef)
  );
  background: linear-gradient(90deg, #2145ef 0%, #21baef 101.45%);
  left: 56px;
  -webkit-animation: loader3 0.6s infinite;
  animation: loader3 0.6s infinite;
}

@-webkit-keyframes loader1 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes loader1 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes loader3 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@keyframes loader3 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@-webkit-keyframes loader2 {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
    transform: translate(24px, 0);
  }
}

@keyframes loader2 {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
    transform: translate(24px, 0);
  }
}

.navbar .search-form {
  margin-bottom: auto;
  margin-top: auto;
}

.navbar .search-form .form-group {
  float: right !important;
  -webkit-transition: all 0.35s, border-radius 0s;
  transition: all 0.35s, border-radius 0s;
  width: 34px;
  height: 34px;
  background-color: #11161f;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  border-radius: 4px;
  border: 1px solid transparent;
  color: white;
  position: relative;
}

.navbar .search-form .form-group:hover {
  border: 1px solid white;
}

.navbar .search-form .form-group:hover button {
  color: white;
}

.navbar .search-form .form-group:hover input.form-control {
  color: white;
}

.navbar .search-form .form-group input.form-control {
  padding-right: 20px;
  border: 0 none;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: block;
  color: #868e94;
}

.navbar .search-form .form-group input.form-control::-webkit-input-placeholder {
  display: none;
}

.navbar .search-form .form-group input.form-control:-moz-placeholder {
  /* Firefox 18- */
  display: none;
}

.navbar .search-form .form-group input.form-control::-moz-placeholder {
  /* Firefox 19+ */
  display: none;
}

.navbar .search-form .form-group input.form-control:-ms-input-placeholder {
  display: none;
}

.navbar .expandable-search.active {
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
}

.navbar .search-form .form-group:active {
  width: 100%;
  border-radius: 4px;
}

.navbar .search-form .form-group:active button {
  color: white;
}

.navbar .search-form .form-group button {
  position: absolute;
  top: -1px;
  right: -2px;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  color: #868e94;
  left: initial;
  padding: 0;
}

body {
  font-family: "Proxima Nova Regular";
  background: #11161f;
  color: white;
  font-size: 14px;
  overflow-x: hidden;
}

@media (max-width: 769px) {
  body.padding-top {
    padding-top: 58px;
  }
}

.bg-dark {
  background-color: #11161f !important;
}

.bg-dark-gradient-180 {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#11161f),
    color-stop(50%, rgba(17, 22, 31, 0.644993)),
    to(rgba(17, 22, 31, 0))
  );
  background: linear-gradient(
    180deg,
    #11161f 0%,
    rgba(17, 22, 31, 0.644993) 50%,
    rgba(17, 22, 31, 0) 100%
  );
}

.bg-dark-gradient-0 {
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#11161f),
    color-stop(50%, rgba(17, 22, 31, 0.644993)),
    to(rgba(17, 22, 31, 0))
  );
  background: linear-gradient(
    0deg,
    #11161f 0%,
    rgba(17, 22, 31, 0.644993) 50%,
    rgba(17, 22, 31, 0) 100%
  );
}

.bg-dark-gradient-1 {
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#11161f),
    color-stop(50%, rgba(17, 22, 31, 0.644993)),
    to(rgba(17, 22, 31, 0))
  );
  background: linear-gradient(
    0deg,
    #11161f 25%,
    rgba(17, 22, 31, 0.644993) 60%,
    rgba(17, 22, 31, 0) 100%
  );
}

.nav-item .nav-link {
  text-transform: uppercase;
}

.dropdown-menu[data-bs-popper],
.dropdown-menu {
  top: calc(100% - 2px);
  border-radius: 1px;
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

.dropdown-menu-end {
  right: 0;
}

.text-primary {
  color: #21baef !important;
}

.text-secondary {
  color: #1782a7 !important;
}

button,
.btn {
  border-radius: 4px;
  -webkit-box-shadow: 0 2px transparent;
  box-shadow: 0 2px transparent;
  text-transform: uppercase;
  color: white;
  font-family: "Proxima Nova Bold";
}

button:hover,
.btn:hover {
  color: white;
}

button:focus,
.btn:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: white;
}

button:active,
.btn:active {
  -webkit-box-shadow: 0 2px transparent;
  box-shadow: 0 2px transparent;
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
  color: white;
}

.btn-primary {
  background: #21baef;
  border-color: #21baef;
}

.btn-primary:hover {
  background-color: #539fdb;
  border-color: #539fdb;
}

.btn-primary:active {
  background-color: #539fdb;
  border-color: #539fdb;
}

.btn-primary:focus {
  background-color: #539fdb;
  border-color: #539fdb;
}

.btn-outline-primary {
  color: #21baef;
  border: 1px solid #21baef;
}

.btn-outline-primary:hover {
  background: #21baef;
  border-color: #21baef;
}

.btn-outline-primary:active {
  background: #21baef;
  border-color: #21baef;
}

.btn-outline-primary:focus {
  background: #21baef;
  border-color: #21baef;
}

.btn-primary-gradient {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#329d9a),
    color-stop(101.45%, #21baef)
  );
  background: linear-gradient(90deg, #329d9a 0%, #21baef 101.45%);
  border-color: transparent;
  border-left-color: #329d9a;
  border-right: #21baef;
}

.btn-primary-gradient:hover {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#329d9a),
    color-stop(101.45%, #21baef)
  );
  background: linear-gradient(90deg, #329d9a 0%, #21baef 101.45%);
  border-color: transparent;
  border-left-color: #1782a7;
}

.btn-primary-gradient:active {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#329d9a),
    color-stop(101.45%, #21baef)
  );
  background: linear-gradient(90deg, #329d9a 0%, #21baef 101.45%);
  border-color: transparent;
  border-left-color: #1782a7;
}

.btn-primary-gradient:focus {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#329d9a),
    color-stop(101.45%, #21baef)
  );
  background: linear-gradient(90deg, #329d9a 0%, #21baef 101.45%);
  border-color: transparent;
  border-left-color: #1782a7;
}

.btn-secondary {
  background: white !important;
  border-color: white !important;
  color: #11161f;
}

.btn-secondary:hover {
  background-color: white !important;
  border-color: white;
  color: #11161f;
}

.btn-secondary:active {
  background-color: white;
  border-color: white;
  color: #11161f;
}

.btn-secondary:focus {
  background-color: white;
  border-color: white;
  color: #11161f;
}

.btn-google {
  background: #4285f4;
  border-color: #4285f4;
}

.btn-google:hover {
  background-color: #72a4f7;
  border-color: #72a4f7;
}

.btn-google:active {
  background-color: #72a4f7;
  border-color: #72a4f7;
}

.btn-google:focus {
  background-color: #72a4f7;
  border-color: #72a4f7;
}

.btn-facebook {
  background: #4267b2;
  border-color: #4267b2;
}

.btn-facebook:hover {
  background-color: #6283c5;
  border-color: #6283c5;
}

.btn-facebook:active {
  background-color: #6283c5;
  border-color: #6283c5;
}

.btn-facebook:focus {
  background-color: #6283c5;
  border-color: #6283c5;
}

.dropdown-item {
  color: #868e94;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #11161f;
  background-color: transparent;
}

.logo {
  width: 150px;
}

.text-muted {
  color: #868e94 !important;
}

.offcanvas {
  background-color: #11161f;
  overflow: auto;
}

.offcanvas .list-group .list-group-item {
  background-color: inherit;
  border: 0;
  padding: 1rem 1rem;
}

.offcanvas .list-group .list-group-item .link {
  color: #868e94;
  text-decoration: none;
  text-transform: uppercase;
}

.offcanvas .list-group .list-group-item .link:hover {
  color: white;
}

.offcanvas .list-group .list-group-item .link.active {
  color: white;
  font-family: "Proxima Nova Bold";
}

.offcanvas .list-group .list-group-item.profile {
  background-color: #1a222f;
}

.offcanvas .list-group .profile {
  background-color: #1a222f;
  border-radius: 4px;
}

.offcanvas .list-group .profile hr {
  background-color: #5974a3;
  margin: 0.5rem 0;
}

.banner {
  height: 40vw;
  background-color: #11161f;
  margin-bottom: 50px;
}

@media (max-width: 769px) {
  .banner {
    margin-bottom: 50px;
  }
}

.banner .carousel {
  height: inherit;
}

.banner .carousel .carousel-inner {
  height: inherit;
}

.banner .carousel .carousel-inner .carousel-item {
  height: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.banner .carousel .carousel-inner .carousel-item .movie-link {
  position: absolute;
  width: 100%;
  height: 100%;
}

.banner .carousel .carousel-inner .carousel-item video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.banner .carousel .carousel-inner .carousel-caption {
  left: 0;
  right: 0;
  bottom: -1px;
}

.banner
  .carousel
  .carousel-inner
  .carousel-caption
  .movie-details
  .banner-movie-title {
  font-size: 30px;
}

@media (min-width: 768px) {
  .banner
    .carousel
    .carousel-inner
    .carousel-caption
    .movie-details
    .banner-movie-title {
    font-size: 48px;
  }
}

.banner .carousel .carousel-inner .carousel-caption .movie-details .hd-logo {
  position: relative;
}

.banner
  .carousel
  .carousel-inner
  .carousel-caption
  .movie-details
  .hd-logo::after {
  content: "+";
  position: absolute;
  top: -3px;
  right: 0px;
  height: 10px;
  width: 10px;
  background: #68b131;
  border-radius: 10px;
  color: #fff;
  font-weight: 700;
  font-size: 10px;
  text-align: center;
  line-height: 10px;
  display: inline-block;
}

.banner .carousel .carousel-inner .carousel-caption .movie-details .rating {
  font-size: 12px;
  padding: 1px 5px;
  border: 1px solid #fff;
  color: #fff;
  margin: 0 10px;
  border-radius: 4px;
  font-family: "Proxima Nova Bold";
}

.banner .carousel .carousel-indicators {
  margin-bottom: -50px;
}

.banner .carousel .carousel-indicators [data-bs-target] {
  height: 10px;
  width: 10px;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 100%;
  background-color: #21baef;
  opacity: 0.2;
}

.banner .carousel .carousel-indicators .active {
  opacity: 1;
}

@media (min-width: 768px) {
  .banner .carousel .carousel-control-next,
  .banner .carousel .carousel-control-prev {
    top: 100px;
    bottom: 100px;
  }
}

.banner-content-mobile .movie-details .banner-movie-title {
  font-size: 30px;
}

@media (min-width: 768px) {
  .banner-content-mobile .movie-details .banner-movie-title {
    font-size: 48px;
  }
}

.banner-content-mobile .movie-details .hd-logo {
  position: relative;
}

.banner-content-mobile .movie-details .hd-logo::after {
  content: "+";
  position: absolute;
  top: -3px;
  right: 0px;
  height: 10px;
  width: 10px;
  background: #68b131;
  border-radius: 10px;
  color: #fff;
  font-weight: 700;
  font-size: 10px;
  text-align: center;
  line-height: 10px;
  display: inline-block;
}

.banner-content-mobile .movie-details .rating {
  font-size: 12px;
  padding: 1px 5px;
  border: 1px solid #fff;
  color: #fff;
  margin: 0 10px;
  border-radius: 4px;
  font-family: "Proxima Nova Bold";
}

.banner.movie {
  margin-bottom: 5px;
}

a {
  text-decoration: none;
}

.font-bold {
  font-family: "Proxima Nova Bold";
}

.font-light {
  font-family: "Proxima Nova Thin";
}

.movies-tiles {
  position: relative;
  margin-bottom: 24px;
  position: relative;
}

.movies-tiles .head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}

.movies-tiles .head .title {
  margin-right: auto;
  font-family: "Proxima Nova Bold";
  font-size: 20px;
  text-decoration: none;
  color: white;
}

.movies-tiles .head .link {
  margin-left: auto;
  text-decoration: none;
  color: #21baef;
}

.movies-tiles .swiffy-slider .slider-container > * {
  width: 202px;
}

.movies-tiles .swiffy-slider .movie-item {
  position: relative;
  overflow: hidden;
}

.movies-tiles .swiffy-slider .movie-item .caption {
  text-align: center;
  font-size: 12px;
}

.movies-tiles .swiffy-slider .movie-item .caption .movie-title {
  font-family: "Proxima Nova Bold";
}

.movies-tiles .swiffy-slider .movie-item .caption .movie-year {
  font-family: "Proxima Nova Thin";
}

.movies-tiles .swiffy-slider .movie-item .overlay {
  position: absolute;
  height: 100%;
  top: 0;
  background: white;
  width: 100%;
  left: -100%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #11161f;
}

.movies-tiles .swiffy-slider .movie-item .overlay .top {
  margin-bottom: auto;
}

.movies-tiles .swiffy-slider .movie-item .overlay .top .overlay-title {
  font-size: 16px;
  color: #21baef;
  text-transform: capitalize;
  font-family: "Proxima Nova Bold";
}

.movies-tiles .swiffy-slider .movie-item .overlay .top .overlay-subtitle {
  font-size: 13px;
  color: #868e94;
  margin-bottom: 5px;
}

.movies-tiles .swiffy-slider .movie-item .overlay .top .content {
  position: relative;
  font-size: 13px;
  color: #868e94;
  margin-bottom: 10px;
}

.movies-tiles
  .swiffy-slider
  .movie-item
  .overlay
  .top
  .content
  .overlay-description {
  overflow: hidden;
  line-height: 1;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  position: relative;
}

.movies-tiles
  .swiffy-slider
  .movie-item
  .overlay
  .top
  .content
  .overlay-description::after {
  content: "more»";
  position: absolute;
  color: #21baef;
  bottom: 0;
  right: 0;
}

.movies-tiles .swiffy-slider .movie-item .overlay .top .title-wrap {
  margin-bottom: 5px;
  font-size: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.movies-tiles .swiffy-slider .movie-item .overlay .top .title-wrap .title-head {
  color: #868e94;
  font-family: "Proxima Nova Bold";
}

.movies-tiles .swiffy-slider .movie-item .overlay .top .title-wrap .title-name {
  color: #21baef;
  margin-left: 5px;
}

.movies-tiles .swiffy-slider .movie-item .overlay .bottom {
  margin-top: auto;
}

@media (min-width: 768px) {
  .movies-tiles .swiffy-slider .movie-item:hover .overlay {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    left: 0%;
  }
}

.movies-tiles .swiffy-slider li img {
  width: 100%;
  height: 298px;
}

.movies-tiles .swiffy-slider .slider-nav {
  padding: 0;
}

.movies-tiles .swiffy-slider .slider-nav::before {
  width: 2rem;
  height: 2rem;
}

.movies-tiles .swiffy-slider .slider-nav::after {
  width: 2rem;
  height: 2rem;
}

@media (min-width: 769px) {
  .movies-tiles .swiffy-slider .slider-nav.slider-nav-prev {
    left: -32px;
  }
}

@media (min-width: 769px) {
  .movies-tiles .swiffy-slider .slider-nav.slider-nav-next {
    right: -32px;
  }
}

.movies-thumb-tiles {
  position: relative;
  margin-bottom: 24px;
}

.movies-thumb-tiles .head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}

.movies-thumb-tiles .head .title {
  margin-right: auto;
  font-family: "Proxima Nova Bold";
  font-size: 20px;
  text-decoration: none;
  color: white;
}

.movies-thumb-tiles .swiffy-slider .slider-container > * {
  width: 202px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.movies-thumb-tiles .swiffy-slider .slider-container:hover {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.movies-thumb-tiles .swiffy-slider .movie-item {
  position: relative;
  overflow: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.movies-thumb-tiles .swiffy-slider .movie-item .caption {
  text-align: center;
  font-size: 12px;
  margin-top: 5px;
}

.movies-thumb-tiles .swiffy-slider .movie-item .caption .description {
  font-family: "Proxima Nova Bold";
  width: 100%;
  overflow: hidden;
  line-height: 1;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  position: relative;
}

.movies-thumb-tiles .swiffy-slider .movie-item .caption .movie-year {
  font-family: "Proxima Nova Thin";
}

.movies-thumb-tiles .swiffy-slider .movie-item .overlay {
  position: absolute;
  top: 50%;
  background: transparent;
  left: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  border-radius: 100%;
  border: 3px white solid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.movies-thumb-tiles .swiffy-slider .movie-item .overlay:hover {
  background: #21baef;
  visibility: visible;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.movies-thumb-tiles .swiffy-slider .movie-item:hover {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (min-width: 768px) {
  .movies-thumb-tiles .swiffy-slider .movie-item:hover .overlay {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    visibility: visible;
    opacity: 1;
  }
}

.movies-thumb-tiles .swiffy-slider li img {
  width: 100%;
  height: 122px;
}

.movies-thumb-tiles .swiffy-slider .slider-nav {
  padding: 0;
}

.movies-thumb-tiles .swiffy-slider .slider-nav::before {
  width: 2rem;
  height: 2rem;
}

.movies-thumb-tiles .swiffy-slider .slider-nav::after {
  width: 2rem;
  height: 2rem;
}

@media (min-width: 769px) {
  .movies-thumb-tiles .swiffy-slider .slider-nav.slider-nav-prev {
    left: -32px;
  }
}

@media (min-width: 769px) {
  .movies-thumb-tiles .swiffy-slider .slider-nav.slider-nav-next {
    right: -32px;
  }
}

.movie-item {
  position: relative;
  overflow: hidden;
  width: 216px;
  height: 312px;
}

.movie-item img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

footer {
  font-size: 12px;
  overflow-x: hidden;
}

footer h6 {
  font-size: 12px;
}

footer p {
  margin-bottom: 5px;
}

.favorite-image-wrap {
  width: 45px;
  height: 45px;
  position: relative;
  border-radius: 100%;
  overflow: hidden;
}

@media (max-width: 769px) {
  .favorite-image-wrap {
    width: 38px;
    height: 38px;
  }
}

.favorite-image-wrap img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.auth-page {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
}

.auth-page .auth-wrap {
  background: #000000cc;
  height: 100%;
  /* min-height: inherit; */
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  top: 0;
}

.auth-page .auth-wrap .auth-form-wrap {
  background-color: #11161f;
}

.font-size-24 {
  font-size: 24px;
}

.faint-line {
  width: 100%;
  border-bottom: 1px #868e94 solid;
}

.btn-icon {
  width: 60px;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.bg-facebook {
  background: #4267b2;
}

.bg-google {
  background: #4285f4;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -3px;
}

.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

a {
  color: #21baef;
}

a:hover {
  color: #1782a7;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #21baef;
  border-color: #21baef;
}

.navbar {
  z-index: 1030;
}

.profile-img-wrap {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-img-wrap img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
/*# sourceMappingURL=main.css.map */
