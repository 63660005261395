@media (max-width: 768px) {
  .dev {
    margin: 20px;
  }
}

@media (min-width: 768px) {
  .dev {
    margin: 100px;
    padding: 100px;
  }
}

@media (max-width: 768px) {
  .login-logo {
    margin-left: 130px;
  }
}

@media (min-width: 768px) {
  .playlisttile {
    margin-top: 50px;
  }
}

.c-outer-div {
  background-color: #262626;
  padding: 6px;
}
.c-inner-div {
  margin: auto;
  width: 100%;
  border: 1px solid #21baef;
  border-radius: 8px;
  padding: 10px;
  margin-top: 18px;
  background-color: #262626;
}
.line {
  position: absolute;
  z-index: -1;
  bottom: 12px;
  left: 5px;
  display: block;
  border-top: 2px solid red;
}
.secondaryColor {
  color: #ff5200;
}
