/* Permalink: https://swiffyslider.com/configuration/?slider-item-show=slider-item-show6&slider-item-ratio=slider-item-ratio&slider-item-ratio-value=slider-item-ratio-3x4&slider-nav-visible=slider-nav-visible&slider-nav-touch=slider-nav-touch&slider-nav-page=slider-nav-page&slider-nav-noloop=slider-nav-noloop&slider-nav-autopause=slider-nav-autopause&slider-indicators-sm=slider-indicators-sm&slider-nav-animation=slider-nav-animation */
.swiffy-slider {
  position: relative;
  display: block;
  width: 100%;
  --swiffy-slider-snap-align: center;
  --swiffy-slider-item-width: 100%;
  --swiffy-slider-item-gap: 1rem;
  --swiffy-slider-item-reveal: 0rem;
  --swiffy-slider-item-ratio: 2/1;
  --swiffy-slider-item-count: 1;
  --swiffy-slider-nav-light: #fff;
  --swiffy-slider-nav-dark: #333;
  --swiffy-slider-nav-zoom: 1;
  --swiffy-slider-track-opacity: 0.1;
  --swiffy-slider-track-height: 0;
  --swiffy-slider-nav-outside-size: 3.5rem;
  --swiffy-slider-indicator-outside-size: 1.5rem;
  --swiffy-slider-animation-duration: 0.75s;
  --swiffy-slider-animation-delay: 0s;
  --swiffy-slider-animation-timing: ease-in-out;
}

.swiffy-slider,
.swiffy-slider::after,
.swiffy-slider::before {
  box-sizing: border-box;
}

.swiffy-slider ::-webkit-scrollbar {
  height: var(--swiffy-slider-track-height);
}

.swiffy-slider ::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, var(--swiffy-slider-track-opacity));
}

.swiffy-slider ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 1rem;
}

.swiffy-slider ::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.6);
}

.slider-container {
  --swiffy-slider-item-gap-totalwidth: calc(
    var(--swiffy-slider-item-gap) * (var(--swiffy-slider-item-count) - 1)
  );
  /* --swiffy-slider-item-width: calc(
    (
        100% - var(--swiffy-slider-item-reveal) -
          var(--swiffy-slider-item-gap-totalwidth)
      ) / 0
  ); */
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  display: grid;
  align-items: center;
  height: 100%;
  grid: auto / auto-flow max-content;
  grid-auto-rows: 100%;
  /* grid-auto-columns: var(--swiffy-slider-item-width); */
  grid-auto-flow: column;
  grid-gap: var(--swiffy-slider-item-gap);
  list-style: none;
  margin: 0;
  padding: 0;
  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.4)
    rgba(0, 0, 0, var(--swiffy-slider-track-opacity));
  background-clip: padding-box;
}

.slider-container > * {
  /*The slides*/
  scroll-snap-align: var(--swiffy-slider-snap-align);
  position: relative;
  width: 100%;
  height: 100%;
}

.slider-item-helper .slider-container > * {
  background-size: cover;
  background-color: #e1e1e1;
  background-position: 50% 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-item-helper:not(.slider-item-ratio) .slider-container > * {
  min-height: 20rem;
}

.slider-item-ratio .slider-container > * > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-item-ratio-contain .slider-container > * > * {
  object-fit: contain;
}

.slider-item-ratio .slider-container > *::after {
  display: block;
  padding-top: calc(100% / (var(--swiffy-slider-item-ratio)));
  content: "";
}

.slider-item-ratio-32x9 {
  --swiffy-slider-item-ratio: 32/9;
}

.slider-item-ratio-21x9 {
  --swiffy-slider-item-ratio: 21/9;
}

.slider-item-ratio-16x9 {
  --swiffy-slider-item-ratio: 16/9;
}

.slider-item-ratio-4x3 {
  --swiffy-slider-item-ratio: 4/3;
}

.slider-item-ratio-2x1 {
  --swiffy-slider-item-ratio: 2/1;
}

.slider-item-ratio-1x1 {
  --swiffy-slider-item-ratio: 1/1;
}

.slider-item-ratio-3x4 {
  --swiffy-slider-item-ratio: 3/4;
}

.slider-nav-scrollbar {
  --swiffy-slider-track-height: 0.5rem;
}

.slider-nav-scrollbar .slider-container {
  scrollbar-width: thin;
}

.slider-nav-nodelay .slider-container {
  scroll-behavior: auto;
}

.slider-indicators {
  position: absolute;
  right: 2rem;
  bottom: 0;
  left: 2rem;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-bottom: 1rem;
  list-style: none;
}

.slider-nav-scrollbar .slider-indicators {
  margin-bottom: calc(1rem + var(--swiffy-slider-track-height));
}

.slider-indicators > *.active {
  opacity: 1;
}

.swiffy-slider.slider-indicators-outside .slider-nav {
  margin-bottom: var(--swiffy-slider-indicator-outside-size);
}

.swiffy-slider.slider-indicators-outside {
  padding-bottom: var(--swiffy-slider-indicator-outside-size);
}

.swiffy-slider.slider-indicators-outside.slider-indicators,
.swiffy-slider.slider-indicators-outside .slider-indicators {
  margin-bottom: 0;
}

.slider-indicators > * {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 2rem;
  height: 0.2rem;
  padding: 0;
  border: 0.4rem solid transparent;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  opacity: 0.5;
  transition: opacity 0.4s ease;
}

.slider-indicators-square.slider-indicators > *,
.slider-indicators-square .slider-indicators > * {
  width: 0.5rem;
  height: 0.5rem;
  border: 0.4rem solid transparent;
}

.slider-indicators-round.slider-indicators > *,
.slider-indicators-round .slider-indicators > * {
  width: 0.5rem;
  height: 0.5rem;
  border: 0.4rem solid transparent;
  border-radius: 50%;
}

.slider-indicators-highlight.slider-indicators > *.active,
.slider-indicators-highlight .slider-indicators > *.active {
  border: 0.33rem solid transparent;
  padding: 0.07rem;
}

.slider-nav {
  position: absolute;
  top: 0;
  bottom: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  visibility: hidden;
  opacity: 0.8;
  transition: visibility 0.1s, opacity 0.2s linear;
  margin-bottom: var(--swiffy-slider-track-height);
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.5));
  transform: scale(var(--swiffy-slider-nav-zoom));
}

.slider-nav::before {
  position: absolute;
  content: "";
  padding: 0.5rem;
  width: 3rem;
  height: 3rem;
}

.slider-nav::after {
  content: "";
  -webkit-mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'></path></svg>");
  mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'></path></svg>");
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: var(--swiffy-slider-nav-light);
  background-origin: content-box;
  width: 3rem;
  height: 3rem;
}

.slider-nav-arrow .slider-nav::after {
  mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z'></path></svg>");
}

.slider-nav-chevron .slider-nav::after {
  mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z'></path></svg>");
}

.slider-nav-caret .slider-nav::after {
  mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'><path d='M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z'></path></svg>");
}

.slider-nav-caretfill .slider-nav::after {
  mask: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'><path d='m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z'></path></svg>");
}

.swiffy-slider:hover .slider-nav {
  visibility: visible;
}

.slider-nav-outside .slider-container {
  margin: 0 var(--swiffy-slider-nav-outside-size);
}

.slider-nav-outside .slider-nav {
  padding: 0;
}

.swiffy-slider .slider-nav:hover {
  opacity: 1;
}

.slider-nav-square .slider-nav {
  padding: 0;
}

.slider-nav-round .slider-nav::before,
.slider-nav-square .slider-nav::before {
  background-color: var(--swiffy-slider-nav-light);
}

.slider-nav-round .slider-nav::after,
.slider-nav-square .slider-nav::after {
  background-color: var(--swiffy-slider-nav-dark);
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
}

.slider-nav-round .slider-nav::before {
  border-radius: 50%;
}

.slider-nav-round .slider-nav::after {
  mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' %3E%3Cpath fill-rule='evenodd' d='M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z'%3E%3C/path%3E%3C/svg%3E");
}

.slider-nav-dark .slider-nav::after {
  background-color: var(--swiffy-slider-nav-dark);
}

.slider-nav-dark.slider-nav-round .slider-nav::before,
.slider-nav-dark.slider-nav-square .slider-nav::before {
  background-color: var(--swiffy-slider-nav-dark);
}

.slider-nav-dark.slider-nav-round .slider-nav::after,
.slider-nav-dark.slider-nav-square .slider-nav::after {
  background-color: var(--swiffy-slider-nav-light);
}

.slider-nav-sm {
  --swiffy-slider-nav-zoom: 0.75;
  --swiffy-slider-nav-outside-size: 2.5rem;
}

.slider-nav.slider-nav-next::after {
  transform: rotate(180deg);
}

.slider-nav.slider-nav-next {
  right: 0;
}

.slider-nav-visible .slider-nav {
  visibility: visible;
}

.slider-nav-dark .slider-nav {
  opacity: 0.6;
}

.slider-indicators-dark.slider-indicators > *,
.slider-indicators-dark .slider-indicators > * {
  filter: invert(1);
}

.slider-item-snapstart {
  --swiffy-slider-snap-align: start;
}

.slider-item-nosnap {
  --swiffy-slider-snap-align: unset;
}

.slider-item-nogap {
  --swiffy-slider-item-gap: 0rem;
}

.slider-item-reveal {
  --swiffy-slider-item-reveal: 8rem;
}

.slider-item-snapstart.slider-item-reveal {
  --swiffy-slider-item-reveal: 4rem;
}

.slider-item-show2 {
  --swiffy-slider-item-count: 2;
}

.slider-item-show3 {
  --swiffy-slider-item-count: 3;
}

.slider-item-show4 {
  --swiffy-slider-item-count: 4;
}

.slider-item-show5 {
  --swiffy-slider-item-count: 5;
}

.slider-item-show6 {
  --swiffy-slider-item-count: 6;
}

.slider-nav-mousedrag .slider-container {
  cursor: grab;
}

.slider-nav-mousedrag .slider-container.dragging {
  scroll-snap-type: unset;
  scroll-behavior: unset;
  user-select: none;
}

.slider-nav-mousedrag .slider-container::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .slider-nav-animation.slider-nav-animation-fast {
    --swiffy-slider-animation-duration: 0.25s;
  }
  .slider-nav-animation.slider-nav-animation-slow {
    --swiffy-slider-animation-duration: 1.25s;
  }
  .slider-nav-animation .slider-container > * > * {
    transition: opacity var(--swiffy-slider-animation-duration)
        var(--swiffy-slider-animation-timing),
      transform var(--swiffy-slider-animation-duration)
        var(--swiffy-slider-animation-timing);
    transition-delay: var(--swiffy-slider-animation-delay);
  }
  .slider-nav-animation .slider-container .slide-visible > * {
    transition: opacity var(--swiffy-slider-animation-duration)
        var(--swiffy-slider-animation-timing),
      transform var(--swiffy-slider-animation-duration)
        var(--swiffy-slider-animation-timing);
    transition-delay: var(--swiffy-slider-animation-delay);
  }
  .slider-nav-animation.slider-nav-animation-fadein .slider-container > * > * {
    opacity: 0.5;
  }
  .slider-nav-animation.slider-nav-animation-scale .slider-container > * > * {
    transform: scale(0.9);
  }
  .slider-nav-animation.slider-nav-animation-appear .slider-container > * > * {
    opacity: 0.3;
    transform: scale(0.9);
  }
  .slider-nav-animation.slider-nav-animation-scaleup .slider-container > * > * {
    transform: scale(0.25);
  }
  .slider-nav-animation.slider-nav-animation-turn .slider-container > * > * {
    transform: rotateY(70deg);
  }
  .slider-nav-animation.slider-nav-animation-slideup .slider-container > * > * {
    transform: translateY(60%) scale(0.99);
  }
  .slider-nav-animation.slider-nav-animation-slideup .slider-container {
    overflow-y: hidden;
  }
  .slider-nav-animation .slider-container > *.slide-visible > * {
    opacity: 1;
    transform: none;
  }
}

@media (min-width: 992px) {
  .slider-item-show6:not(.slider-item-snapstart) .slider-container > *,
  .slider-item-show4:not(.slider-item-snapstart) .slider-container > *,
  .slider-item-show2:not(.slider-item-snapstart) .slider-container > * {
    scroll-snap-align: unset;
  }
  .slider-item-show6:not(.slider-item-snapstart) .slider-container > *::before,
  .slider-item-show4:not(.slider-item-snapstart) .slider-container > *::before,
  .slider-item-show2:not(.slider-item-snapstart) .slider-container > *::before {
    content: " ";
    display: block;
    position: absolute;
    left: calc((var(--swiffy-slider-item-gap) / 2) * -1);
    top: 0;
    /*FF fix setting w+h to 1 px - otherwise snap will not occur*/
    width: 1px;
    height: 1px;
    scroll-snap-align: var(--swiffy-slider-snap-align);
  }
  .slider-nav-outside-expand .slider-nav {
    margin-left: -5rem;
  }
  .slider-nav-outside-expand .slider-nav.slider-nav-next {
    margin-right: -5rem;
  }
  .slider-nav-sm.slider-nav-outside-expand .slider-nav {
    margin-left: -4rem;
  }
  .slider-nav-sm.slider-nav-outside-expand .slider-nav.slider-nav-next {
    margin-right: -4rem;
  }
  .slider-indicators-sm.slider-indicators {
    display: none;
  }
}

@media (max-width: 992px) {
  .swiffy-slider {
    --swiffy-slider-track-height: 0rem;
    --swiffy-slider-item-reveal: 0rem;
    --swiffy-slider-item-count: 1;
    --swiffy-slider-nav-zoom: 0.75;
  }
  .slider-item-reveal {
    --swiffy-slider-item-reveal: 4rem;
  }
  .slider-item-snapstart.slider-item-reveal {
    --swiffy-slider-item-reveal: 2rem;
  }
  .slider-item-show6 .slider-container {
    grid-auto-columns: calc(25% - (var(--swiffy-slider-item-gap) / 4 * 3));
  }
  .slider-item-show6.slider-item-reveal .slider-container {
    grid-auto-columns: calc(
      25% - (var(--swiffy-slider-item-gap) / 4 * 3) - 0.5rem
    );
  }
  .slider-item-show6.slider-item-reveal .slider-container > * {
    scroll-snap-align: unset;
  }
  .slider-item-show6.slider-item-reveal .slider-container > *::before {
    content: " ";
    display: block;
    position: absolute;
    left: calc((var(--swiffy-slider-item-gap) / 2) * -1);
    top: 0;
    /*FF fix setting w+h to 1 px - otherwise snap will not occur*/
    width: 1px;
    height: 1px;
    scroll-snap-align: center;
  }
  .slider-nav::after {
    width: 2rem !important;
    height: 2rem !important;
    padding: 0.3rem !important;
  }
  .slider-nav-outside .slider-container {
    margin: 0 2rem;
  }
  .slider-nav-outside-expand .slider-nav {
    padding: 0;
    margin-left: -2rem;
  }
  .slider-nav-outside-expand .slider-nav.slider-nav-next {
    padding: 0;
    margin-right: -2rem;
  }
  .slider-indicators-square.slider-indicators > *,
  .slider-indicators-square .slider-indicators > *,
  .slider-indicators-round.slider-indicators > *,
  .slider-indicators-round .slider-indicators > * {
    width: 0.3rem;
    height: 0.3rem;
  }
  .slider-indicators {
    margin-bottom: 0.5rem;
    display: none;
  }
  .slider-nav-scrollbar .slider-indicators {
    margin-bottom: 0rem;
  }
  .slider-indicators > * {
    width: 1rem;
    height: 0.125rem;
    border-width: 0.25rem;
  }
  .slider-indicators-sm .slider-indicators,
  .slider-indicators-sm.slider-indicators {
    display: flex;
  }
}

@media (max-width: 768px) {
  .slider-item-show6 .slider-container {
    grid-auto-columns: calc(70% - (var(--swiffy-slider-item-gap) / 0.32));
  }
  .slider-item-show6.slider-item-reveal .slider-container {
    grid-auto-columns: calc(50% - (var(--swiffy-slider-item-gap) / 2) - 1.5rem);
  }
}

@media (hover: none) {
  .swiffy-slider:not(.slider-nav-touch) .slider-nav {
    display: none;
  }
  .swiffy-slider:not(.slider-nav-touch).slider-nav-outside-expand
    .slider-container,
  .swiffy-slider:not(.slider-nav-touch).slider-nav-outside .slider-container {
    margin: 0 0rem;
  }
  .slider-item-nosnap-touch {
    --swiffy-slider-snap-align: unset;
  }
}
